<template>
<div style="max-width: 1000px;" class="ma-auto mt-3">
  <vue-headful :title="'Учебные планы'"/>
  <v-layout style="max-width: 100%;">
    <v-btn class="align-self-center" small outlined style="text-transform: inherit;padding: 2px 8px;font-size: 14px;" @click="$router.go(-1)" color="#004992">
      <v-icon left>mdi-arrow-left-circle-outline</v-icon>
      Назад
    </v-btn>
    <v-card-title class="pl-3">
      Учебные планы
    </v-card-title>
    <v-spacer></v-spacer>
    <v-text-field
        @blur="saveFilter"
        v-model="search"
        prepend-inner-icon="mdi-magnify"
        label="Поиск"
        single-line
        hide-details
        solo
        class="mb-3 search-field"
        clearable
    ></v-text-field>
  </v-layout>
  <v-card>
    <v-data-table :loading="loading" :page.sync="page" @update:page="savePage" :search="search" :items="plans" :headers="headers" :items-per-page="15">
      <template v-slot:item.fileName="{ item }">
        <v-layout>
          <v-icon left>mdi-link</v-icon>
          <a :href='`#/Plans/${item.planID}`'>
            {{item.fileName}}
          </a>
        </v-layout>

      </template>
    </v-data-table>
  </v-card>

</div>
</template>

<script>
import plans from "@/helpers/api/plans";
export default {
  name: "PlansList",
  data () {
    return {
      plans: [],
      search: null,
      page: 1,
      loading: true
    }
  },
  computed: {
    headers () {
      return [
          {text: 'Имя файла', value: 'fileName'},
          {text: 'Специальность', value: 'titul'},
          {text: 'Скан-копия', value: 'scan'},
      ]
    }
  },
  mounted() {

    this.load()
  },
  methods: {
    savePage(e) {
      sessionStorage.setItem('plansListPage', e)
    },
    saveFilter() {
      if(this.search) {
        sessionStorage.setItem('plansListFilter', this.search)
      } else {
        sessionStorage.setItem('plansListFilter', '')
      }
    },
    async load () {
      this.loading = true
      this.plans = await plans.getPlans()
      this.loading = false

      if(sessionStorage.getItem('plansListPage')) {
        this.page = Number(sessionStorage.getItem('plansListPage'))
      }
      if(sessionStorage.getItem('plansListFilter')) {
        this.search = sessionStorage.getItem('plansListFilter')
      }
    }
  }
}
</script>

<style scoped>

</style>
