/*
    Методы для работы с API планов
*/
import api from "@/helpers/apiGet";
export default {
    async getPlans (year) {
        // TODO: Тут будет запрос к API
        let response = await api.get('/api/Plans/List', { year: year })
        return response.data.plans
    },
    async getPlanInfo (planID) {
        let response = await api.get('/api/Plans/Info', { planID: planID })
        return response.data
    }
}
