<template>
  <div style="max-width: 1000px;" class="ma-auto">
    <v-layout class="mt-3">
      <v-btn text class="my-auto" to="/Plans" color="primary"><v-icon>mdi-arrow-left</v-icon> список планов</v-btn>
      <v-spacer></v-spacer>
      <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          label="Поиск"
          single-line
          hide-details
          solo
          class="mb-3 search-field"
          clearable
      ></v-text-field>
    </v-layout>
    <v-fade-transition>
      <v-card class="mt-3" v-if="!loading && title && title.name && !loading">
        <v-card-title>
          {{title.name}} ({{title.year}})
        </v-card-title>
        <v-card-subtitle>
          {{title.title}}
        </v-card-subtitle>
      </v-card>
    </v-fade-transition>
    <v-fade-transition>
      <div  v-if="rows && rows.length > 0">
      <v-card class="mt-3 pa-3">
        <v-layout>
          Курс:
          <v-chip :color="scouse === couse ? 'primary' : ''" @click="scouse = couse" small class="ml-2" v-for="couse in courses" :key="couse">{{couse}}</v-chip>
          <v-chip :color="scouse === null ? 'primary' : ''" @click="scouse = null, ssemester = null" small class="ml-2">Все</v-chip>
          <v-fade-transition>
            <div class="ml-3" v-if="scouse">
              Семестр:
              <v-chip @click="ssemester = semester" :color="ssemester === semester ? 'primary' : ''" small class="ml-2" v-for="semester in semesters" :key="semester">{{semester}}</v-chip>
              <v-chip @click="ssemester = null" :color="ssemester === null ? 'primary' : ''" small class="ml-2">Все</v-chip>
            </div>
          </v-fade-transition>
        </v-layout>
      </v-card>
      <v-card class="mt-3" >
        <v-data-table :search="search" mobile-breakpoint="0" disable-pagination hide-default-footer
                      :show-group-by="false" group-by="block" :headers="headers"
                      :items="rows.filter(f=> (scouse === null || scouse === f.course) && (ssemester === null || ssemester === 2 - (f.semester % 2)))">
          <template v-slot:group.header="{items, isOpen, toggle}">
            <th :colspan="headers.length - 1">
              <v-icon class="mr-3" @click="toggle">{{ isOpen ? 'mdi-minus' : 'mdi-plus' }}</v-icon>
              {{ items[0].block }}
            </th>
          </template>
          <template v-slot:item.rpdID="{item}">
            <div>
              <a :href="item.rpdLink" target="_blank" v-if="item.rpdLink">РПД</a><br v-if="item.rpdLink">
              <a :href="item.annLink" target="_blank" v-if="item.rpdLink">Аннотация</a>
            </div>
          </template>
        </v-data-table>
      </v-card>
      </div>
    </v-fade-transition>

  </div>
</template>

<script>
import plans from "@/helpers/api/plans";

let arrayUnique = function (a) {
  return a.reduce(function (p, c) {
    if (p.indexOf(c) < 0) p.push(c);
    return p;
  }, []);
};
export default {
  name: "PlanOpen",
  data () {
    return {
      title: {},
      rows: [],
      scouse: null,
      ssemester: null,
      loading: true,
      search: null,
    }
  },
  computed: {
    courses () {
      return arrayUnique(this.rows.map(m=>m.course)).filter(f=> f).sort((a,b) => a > b ? 1 : -1)
    },
    semesters () {
      return [1,2]
    },
    planID () {
      return this.$route.params.planID
    },
    headers () {
      return [
        {text: 'Блок', value: 'block'},
        {text: 'Дисциплина', value: 'dis'},
        {text: 'РПД', value: 'rpdID'},
        {text: 'Всего часов', value: 'hours'},
        // {text: 'Всего часов', value: 'Всего часов'},
        {text: 'Курс', value: 'course'},
        {text: 'Семестр', value: 'semester'},
        {text: 'Контроль', value: 'formControl'},
        {text: 'Лек', value: 'lecture', visible: this.rows.filter(f=>f.lecture > 0).length > 0 },
        {text: 'Пр', value: 'practical', visible: this.rows.filter(f=>f.practical > 0).length > 0 },
        {text: 'Лаб', value: 'laboratory', visible: this.rows.filter(f=>f.laboratory > 0).length > 0 },
        {text: 'Урок', value: 'groupExercises', visible: this.rows.filter(f=>f.groupExercises > 0).length > 0 },
        {text: 'КСР', value: 'ksr', visible: this.rows.filter(f=>f.ksr > 0).length > 0 },
        {text: 'Контр.', value: 'controls', visible: this.rows.filter(f=>f.controls > 0).length > 0 },
        {text: 'Инд. занятия', value: 'individual', visible: this.rows.filter(f=>f.individual > 0).length > 0 },
      ].filter(f=>f.visible !== false)
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    async load () {
      this.loading = true
      let data  = await plans.getPlanInfo(this.planID)
      this.title = data.title
      this.rows = data.rows
      this.loading = false
    }
  }
}
</script>

<style scoped>

</style>